<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="franquias.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Franquia</th>
                        <th>Responsável</th>
                        <th class="hidden-sm-and-down">Alunos</th>
                        <th class="hidden-sm-and-down">Cidade</th>
                        <th class="hidden-sm-and-down">Status</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(f, i) in franquias.lista" :key="i">
                    <td class="pr-0">
                        <v-avatar size="40" :color="corAvatar(f.franquia)">
                            <span class="white--text headline">{{f.franquia.substr(0, 1)}}</span>
                        </v-avatar>
                    </td>
                    <td :key="f.id_franquia">
                        <router-link exact color="primary" v-if="permissao('franquia_abrir')" :to="`/franquia/gerenciar/${f.id_franquia}`"><strong>{{f.franquia}}</strong></router-link>
                        <strong v-else>{{f.franquia}}</strong><br />
                        <small><strong>ID:</strong> {{f.id_franquia}}</small>
                    </td>
                    <td>
                        {{f.razao_social_nome_completo}}<br />
                        <small><strong>{{f.cnpj_cpf.length == 11 ? 'CPF' : 'CNPJ'}}: </strong>{{f.cnpj_cpf}}</small>
                    </td>
                    <td class="hidden-sm-and-down">{{f.aluno_total}}</td>
                    <td class="hidden-sm-and-down"><template v-if="!!f.cidade">{{f.cidade}} - {{f.uf}}</template><template v-else>-</template></td>
                    <td class="hidden-sm-and-down">
                        <v-chip small color="green" dark v-if="f.ativo"><v-icon left small>mdi-check-circle</v-icon> Ativa</v-chip>
                        <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativa</v-chip>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="franquias.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "FranquiaListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            franquias : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit'])
    },
    methods : {
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}franquia/listar`, {
                limit          : this.paginationLimit,
                offset         : this.paginationLimit * pagina,
                busca          : this.filtro.busca || null,
                id_usuario     : this.filtro.id_usuario || null,
                id_responsavel : this.filtro.id_responsavel || null
            }).then( (res) => {
                this.franquias = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_usuario' : function () {
            this.paginar(0)
        },
        'filtro.id_responsavel' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
